import React, {useMemo} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../layout";
import {Demo1Dashboard} from "./Demo1Dashboard";


export function Dashboard() {
    return <>
        <Demo1Dashboard />
    </>;
}
