import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {commentSlice} from "../app/modules/Comment/_redux/commentSlice";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    comment: commentSlice.reducer
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
