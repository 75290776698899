import { createSlice } from "@reduxjs/toolkit";

const initialCommentsState = {
    listLoading: false,
    actionsLoading: false,
    totalRow: 0,
    totalComment: 0,
    comments: {
        addStatus: false
    },
    commentAddOrChange: null,
    lastError: null,
    commentSetting: {
        isNotLogin: false,
        maxLimitedLikeAndUnlike: {},
        guestInfo: {
            email: '',
            fullName: ''
        },
        theme: {
            backgroundColor: "#2d67ad",
            textColor: "#04416D"
        },
    },
    isEditUsername: false
};
export const callTypes = {
    list: "list",
    add: "add",
    change: "change",
    like: "like"
};

export const commentSlice = createSlice({
    name: "comment",
    initialState: initialCommentsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getCommentById
        get: (state, action) => {
            state.actionsLoading = false;
            state.commentAddOrChange = action.payload.commentAddOrChange;
            state.error = null;
        },
        // get Comments
        gets: (state, action) => {
            const { totalRow, models, pageSize, totalComment } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.models = models;
            state.totalRow = totalRow;
            state.pageSize = pageSize;
            state.totalComment = totalComment;
            setTimeout(() => {
                window.parent.postMessage({ clientHeight: document.body.offsetHeight }, '*')
            })
        },
        // add comment
        add: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.comments.addStatus = action.payload.status
            // state.models.push(action.payload.comment);
        },
        // change comment
        change: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            // state.models = state.models.map(model => {
            //     if (model.id === action.payload.comment.id) {
            //         return action.payload.comment;
            //     }
            //     return model;
            // });
        },
        // remove Comment
        remove: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.models = state.models.filter(el => el.id !== action.payload.id);
        },
        setLogin: (state, action) => {
            state.commentSetting.isNotLogin = action.payload;
        },
        initCommentSetting: (state, action) => {
            // console.log(action);
            state.commentSetting = { ...state.commentSetting, ...action.payload };
        },
        setCommentLike: (state, action) => {
            state.models = state.models.map(item => {
                if (item.commentId == action.payload.commentId) {
                    item.totalLike += action.payload.setLike
                }
                return item;
            })
        },
        setGuestInfo(state, action) {
            state.commentSetting.guestInfo = action.payload.data
        },
        setAvoidSpamLikePost: (state, action) => {
            state.commentSetting = { ...state.commentSetting, maxLimitedLikeAndUnlike: { ...state.commentSetting.maxLimitedLikeAndUnlike, ...action.payload } }
        },
        // loadmore comment
        onLoadMoreComment: (state, action) => {
            state.models = [...state.models, ...action.payload.data];
            state.actionsLoading = false;
            setTimeout(() => {
                window.parent.postMessage({ clientHeight: document.body.offsetHeight }, '*')
            }, 100)
        },
        setHeightLayout: (state, action) => {
            window.parent.postMessage({ clientHeight: document.body.offsetHeight + action.payload }, '*')
        },
        setEditUsername: (state, action) => {
            state.isEditUsername = action.payload;
        },
        setUserInfo: (state, action) => {
            // console.log(action);
            if(state.commentSetting?.userInfo){
                state.commentSetting.userInfo = action.payload;
            }
        }
    }
});

export const
    { setLogin,
        initCommentSetting,
        setCommentLike,
        setAvoidSpamLikePost,
        setHeightLayout,
        setGuestInfo,
        setEditUsername,
        setUserInfo
    }
        = commentSlice.actions;
