import axios from "axios";

export default function setupAxios(axios, store) {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    // axios.interceptors.request.use(
    //   config => {
    //     const {
    //       auth: { authToken }
    //     } = store.getState();
    //
    //     if (authToken) {
    //       config.headers.Authorization = `Bearer ${authToken}`;
    //     }
    //
    //     return config;
    //   },
    //   err => Promise.reject(err)
    // );
}
