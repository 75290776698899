import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {MyPage} from "./pages/MyPage";
import {DashboardPage} from "./pages/DashboardPage";

const CommentModule = lazy(() =>
    import("./modules/Comment/commentModule")
);

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                {/*<ContentRoute path="/comment" component={CommentPage}/>*/}
                <Route path="/comments" component={CommentModule}/>
                <Redirect to="/error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
