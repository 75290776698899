import react from 'react';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import translationEN from './locales/en.json';
import translationVI from './locales/vi.json'

const resources = {
    en : {
        translation : translationEN
    },
    vi : {
        translation : translationVI
    }
}

i18n.
    use(initReactI18next)
    .init({
        fallbackLng : ['vi','en'],
        lng : 'vi',
        resources,
        interpolation : {
            escapeValue : false
        }
    })

export default i18n;