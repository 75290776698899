/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { AuthInit } from "./modules/Auth";
import { Routes } from "../app/Routes";
import { LayoutSplashScreen } from "../_metronic/layout";
import i18nStore from './modules/I18n';
import { I18nextProvider } from 'react-i18next';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function App({ store, persistor, basename }) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            {/* Render routes with provided `Layout`. */}
            <I18nextProvider i18n={i18nStore}>
              <AuthInit>
                {/* <GoogleReCaptchaProvider reCaptchaKey="6LdVB8obAAAAALg42ksysRzMY_oo6SwVF2eNvRoM"> */}
                  <Routes />
                {/* </GoogleReCaptchaProvider> */}
              </AuthInit>
            </I18nextProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
